const ImageZoomEffect = ({ src, alt, className }) => {
  return (
    <div className="img-wrapper">
      <img
        alt={alt || "image"}
        className={`${className} inner-img`}
        src={src}
      />
    </div>
  )
}

export default ImageZoomEffect
