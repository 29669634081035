import ContainerMain from "pages/common/ContainerMain"

import new1 from "images/Channels/Brand/channel-3-1.png"
import new2 from "images/Channels/Brand/channel-3-2.png"
import new3 from "images/Channels/Brand/channel-3-3.png"
import new4 from "images/Channels/Brand/channel-3-4.png"
import ImageZoomEffect from "pages/common/ImageZoomEffect"
import "./NewsBrand.scss"
import { useFormatter } from "helpers/i18n"

export default function NewsBrand() {
  const { __ } = useFormatter()
  return (
    <>
      <div className="dwwyzparpf">
        <ContainerMain>
          <div className="jocsazxeyz">
            <div className="oywxbgmyxe">
              <p>
                {__({
                  defaultMessage:
                    "A comprehensive solution with powerful yet easy-to-use feature set for business users",
                })}
              </p>
            </div>
            <div className="wzgsabgpgm">
              <p>
                {__({
                  defaultMessage:
                    "This is a more direct-to-consumers approach, aimed at your core target audience group. Having your own brand.com is a powerful tool to enhance your online presence, manage your business image, product lisitng and the seamless end-to-end user experience. We focus on user-centric designs to make shopping online an effortless activity for your customers.",
                })}
              </p>
            </div>
            <div className="lqrfsnhyvi">
              <div className="vezulrerem vezulrerem-1">
                <div className="qenwmwtibd">
                  <ImageZoomEffect className="mjxsjbzjkq" alt="" src={new1} />
                </div>
                <div className="ngdwdevsvj tybnthcjgj">
                  <div className="fciywfguey">
                    {__({
                      defaultMessage: "Data Integration",
                    })}
                  </div>
                  <ul className="popglkzayf">
                    <li className="mmssgjsqni">
                      {__({
                        defaultMessage:
                          "Collect & consolidate customer data from across multiple systems (app, web, CRM, POS, data warehouses)",
                      })}
                    </li>
                    <li className="mmssgjsqni">
                      {__({
                        defaultMessage:
                          "Stream customer data to external systems for BI analytics",
                      })}{" "}
                    </li>
                  </ul>
                  <div className="fciywfguey">
                    {__({
                      defaultMessage: "Data Activation",
                    })}
                  </div>
                  <div className="mmssgjsqni">
                    {__({
                      defaultMessage:
                        "Multiple communication channels with personalized messages across the journey",
                    })}
                  </div>
                </div>
              </div>
              <div className="vezulrerem vezulrerem-2">
                <div className="ngdwdevsvj tybnthcjgj">
                  <div className="single-view-of-customer">
                    <div className="fciywfguey">
                      {__({
                        defaultMessage: "Single View of Customer",
                      })}
                    </div>
                    <div className="mmssgjsqni">
                      {__({
                        defaultMessage:
                          " Holistic view of demographic & past purchase data, along with detailed journeys across all touchpoints including keyword search, ad view, product view, add-to-cart, price review, check-out, review/ratings, and many more.",
                      })}
                    </div>
                  </div>
                  <div className="customer-segmentation">
                    <div className="fciywfguey">
                      {__({
                        defaultMessage: "Customer Segmentation",
                      })}
                    </div>
                    <div className="popglkzayf">
                      <div className="mmssgjsqni">
                        {__({
                          defaultMessage:
                            "Build customer segments in real-time with various ways of combination based on customers preferences, behavior, demographic, transactional data, and many more.",
                        })}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="qenwmwtibd">
                  <ImageZoomEffect
                    className="mjxsjbzjkq"
                    alt="Brand.com"
                    src={new2}
                  />
                </div>
              </div>
              <div className="vezulrerem vezulrerem-1">
                <div className="qenwmwtibd">
                  <ImageZoomEffect
                    className="mjxsjbzjkq"
                    alt="Brand.com"
                    src={new3}
                  />
                </div>
                <div className="ngdwdevsvj tybnthcjgj">
                  <div className="fciywfguey">
                    {__({
                      defaultMessage: "Journey Orchestration",
                    })}
                  </div>
                  <ul className="popglkzayf">
                    <li className="mmssgjsqni">
                      {__({
                        defaultMessage:
                          "Craft automated cross-channel journeys with trigger events",
                      })}
                    </li>
                    <li className="mmssgjsqni">
                      {__({
                        defaultMessage:
                          "Personalize the communication at every touchpoint of the journey for each segment.",
                      })}
                    </li>
                  </ul>
                  <div className="fciywfguey">
                    {__({
                      defaultMessage: "Personalization",
                    })}
                  </div>
                  <ul className="popglkzayf">
                    <li className="mmssgjsqni">
                      {__({
                        defaultMessage: "Personalized product recommendations",
                      })}
                    </li>
                    <li className="mmssgjsqni">
                      {__({
                        defaultMessage:
                          "Exclusive web experience with personalized banners, content, promotion, products based on historical data",
                      })}{" "}
                    </li>
                  </ul>
                </div>
              </div>
              <div className="vezulrerem vezulrerem-2">
                <div className="ngdwdevsvj tybnthcjgj">
                  <div className="fciywfguey">
                    {__({
                      defaultMessage: "User Path Optimization",
                    })}
                  </div>
                  <div className="mmssgjsqni">
                    {__({
                      defaultMessage:
                        "Dynamic A/B Testing and AI engine to figure out the optimal journey & channel, pick the best content and deliver it at the perfect moment to maximize conversions.",
                    })}
                  </div>
                  <div className="data-dnalytics">
                    <div className="fciywfguey">
                      {__({
                        defaultMessage: "Data Analytics",
                      })}
                    </div>
                    <ul className="popglkzayf">
                      <li className="mmssgjsqni">
                        {__({
                          defaultMessage:
                            "Access insightful real-time reports of all ongoing campaigns.",
                        })}
                      </li>
                      <li className="mmssgjsqni">
                        {__({
                          defaultMessage:
                            "Discover underperforming campaigns and optimize them for better conversion.",
                        })}
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="qenwmwtibd">
                  <ImageZoomEffect className="mjxsjbzjkq" alt="" src={new4} />
                </div>
              </div>
            </div>
          </div>
        </ContainerMain>
      </div>
    </>
  )
}
